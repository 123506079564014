<template>
	<div>

	<div class="signup">
		<div class="header">
			<div class="top flex-between">
				<div class="left">
					<img src="../../assets/public/logo.png"  @click="tourl">
					<p>|</p>
					<div>
						<p>
							<img src="../../assets/signup/yes.png" alt="">
							<span style="color:#67C23A">我要报名</span>
						</p>
						<p>-------</p>
						<p>
							<img src="../../assets/signup/two.png" alt="">
							<span style="color:#FF7C00">我要支付</span>
						</p>
					</div>
				</div>
				<div class="right flex-around">
					<img src="../../assets/public/phone.png" alt="">
					<div class="time">
						 <p class="t1">{{timePhone}}</p>
                        <p class="t2" style="width:220px">{{timekf}}</p>
					</div>
				</div>
			</div>

		</div>

		<div class="main">
			<div class="m1">
                <div class="order1">
                    <p><img src="../../assets/signup/pass.png"/></p>
                    <p class="r1">{{view.is_audit | audit}}</p>
                    <p style="color:#666">注：订单支付成功即报名成功</p>
                    <p>待支付 <span>¥{{view.order_price}}</span></p>
                </div>
                <div class="order2">
                    <h3>{{view.goods_name}}</h3>
						<div class="a1">
							<p>出行日期</p>
							<p>{{view.start_time}}~{{view.end_time}}</p>
						</div>
						<div class="a1">
							<p>出行人数</p>
							<p><span>成人*{{view.adult_number}}</span><span> 儿童*{{view.children_number}}</span></p>
						</div>
						<div class="a1">
							<p>订单号</p>
							<p>{{view.order_sn}}</p>
						</div>
                </div>
            </div>
            <div class="m2">
                <div class="order1">
                    <p>支付方式</p>
                    <!-- <div :class="sign==1? 'checked' : ''" @click="sign=1">
                        <img src="../../assets/signup/zfb.png"/>
                        <p>支付宝支付</p>
                    </div> -->
                     <div :class="sign==2? 'checked' : ''" @click="sign=2">
                        <img src="../../assets/signup/wx.png"/>
                        <p>微信支付</p>
                    </div>
                    
                </div>
                <div class="order2" @click="pay(view)">
                    提交支付
                </div>
            </div>
		</div>
        <div style="height:30px"></div>
	</div>
	<myfooter></myfooter>

    <dia-pay ref="payCode" :id="view.id"></dia-pay>   
    
	</div>
</template>

<script>
import {orderNowinfoAPI,wxpayAPI,orderStatusAPI ,alipayFormAPI} from "@/api/signup"
import myfooter from '@/components/footer.vue'
import diaPay from '@/components/com/diaPay.vue'

export default {

  components: {
    myfooter,diaPay
  },
  data () {
    return {
       
      value: '1',
      jf: 0,
      yhj: false,
      sign:2,
      id:0,
      view:{},
      

    }
  },
  computed:{
    timePhone(){
        var week=(new Date()).getDay()   
         if(week==0 || week==6){
            return "18817885198"
         }

        var hour=(new Date()).getHours()        
        if (hour>=9 && hour<18) {
          return "400 888 9318"
        } else {
          return "18817885198"
        }
      },
      timekf(){
         var week=(new Date()).getDay()   
         if(week==0 || week==6){
            return "客服时间：18:00~09:00（非工作时段）"
         }
         var hour=(new Date()).getHours()
        if (hour>=9 && hour<18) {
          return "客服时间：09:00~18:00（工作日）"
        } else {
          return "客服时间：18:00~09:00（非工作时段）"
        }
      },
  },
  created () {
        const id = this.$route.query.id
     this.id=id
	 this.getorderview(id)
    
  },
  filters:{
    audit(n){
        if(n==1){
            return "订单已审核通过"
        }else if(n==0){
            return "订单已通过"
        }else if(n==2){
            return "订单未通过审核"
        }else if(n==3){
            return "订单审核中"
        }

    }
  },
  methods: {
	  getorderview(id){
          orderNowinfoAPI({id}).then(res=>{
              this. view=res.cateView
          })
      },
      tourl(){
          this.$router.push({ path: '/'})
      },
      pay(item){
          if(this.sign==1){
            
              var data={
                  order_sn:item.order_sn,
                  return_url:process.env.VUE_APP_PC_URL+"#/user/orderInfo?id="+this.id
              }
                alipayFormAPI(data).then(res=>{
                    console.log(res.pay_form)
                    const div = document.createElement('formdiv');
                    div.innerHTML = res.pay_form;
                    document.body.appendChild(div);
                    document.forms['alipaysubmit'].setAttribute('target', '_blank');
                    document.forms['alipaysubmit'].submit();
                    div.remove();
                })


              return false
          }
        
          if(item.is_audit==1){
			   
			let data={
				order_sn:item.order_sn,
				pay_amount:item.goods_price
			}
            this.$refs.payCode.dialogVisible=true
            this.$nextTick(()=>{
                
                this.$refs.payCode.paydata=data
                this.$refs.payCode.pay()
            })
			}else{
			this.$message.info(item.is_audit==2 ? "审核未通过" : "请等待审核")
		   }
      },
     

  }
}
</script>
<style lang="scss" scoped="scoped">
.paycode{
           
            h2,p{
                text-align: center;
                line-height: 45px;
            }
        }
	.signup {
		background-color: #f9f9f9;
        
		.header {
			background: #fff9ef;
			box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);

			.top {
				width: 1200px;
				margin: 0 auto;
				height: 82px;

				.left {
					display: flex;
					align-items: center;

					>img {
						width: 126px;
					}

					>p {
						margin: 0 30px;
					}

					>div {
						display: flex;
						align-items: center;
						color: #6d380c;

						p {
							margin: 0 10px;
							display: flex;
							align-items: center;

							span {
								font-size: 18px;
								color: #6d380c;
								margin-left: 10px;
							}
						}
					}
				}

				.right {
					width: 260px;

					img {
						width: 21px;
						height: 21px;
					}

					.t1 {
						font-size: 17px;
						font-weight: 500;
					}

					.t2 {
						font-size: 11px;
						color: #666666;
						line-height: 17px;
					}

				}

			}
		}

		.main {
			width: 1200px;
			margin: 0px auto;
            .m1{
                background-color: #fff;
                border-radius: 5px;
                width: 1200px;
                margin-top: 20px;
               padding-bottom: 20px;
                .order1{
                     line-height: 40px;
                    text-align: center;
                    img{
                        margin-top: 20px;
                    }
                    .r1{
                        font-size: 20px;
                        font-weight:500;
                        color: #333333;
                    }
                    p{
                        span{
                            font-size: 24px;
                            font-weight: 500;
                            color: #ff4c12;
                            margin-left: 20px;
                        }
                    }

                }
                .order2{
                    padding: 10px 20px;
                    width: 428px;
                   
                    margin: 20px auto;
                    background: #f5f5f5;
                    border-radius: 6px;
                    line-height: 25px;
                    h3{
                        font-size: 14px;
                        color: #333333;
                    }
                    .a1{
                        margin-top: 10px;
                        display: flex;
                        p:nth-child(1){
                            width: 90px;
                        }
                        span{
                            margin-right: 10px;
                        }
                    }
                }
                
            }
            .m2{
                padding: 20px 0;
                background-color:#fff;
                border-radius: 5px;
                width: 1200px;
                margin: 20px auto;
                .order1{
                    width: 200px;
			        margin: 20px auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div{
                        cursor: pointer;
                         width: 116px;
                        height: 40px;
                         display: flex;
                         align-items: center;
                         justify-content: space-around; 
                         font-size: 14px;
                        font-weight: 400;
                        text-align: left;
                        color: #666666;  
                        border: 1px solid #e6e6e6;
                        border-radius: 5px;                  
                    }
                    .checked{
                       
                        opacity: 1;
                        background: #ffffff;
                        border: 1px solid #ff7c00;
                        border-radius: 5px;
                    }
                }


                .order2{
                        margin: 0 auto;
                        width: 321px;
						height: 45px;
						opacity: 1;
						background: #ff7c00;
						border-radius: 4px;
						display: flex;
						justify-content: center;
						align-items: center;
						color: #fff;
                }
            }

			
		}
	}
</style>
